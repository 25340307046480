import React, { useState, useEffect } from "react";
import { Typography, Collapse, Card, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import {
  getPatientDetails,
  getCaseHistory,
} from "../../api/PatientAPI";

import {
  getPatientPrescriptionHistory,
  cancelPrescription
} from "../../api/PrescriptionsApi"

import styled from "styled-components";
import moment from "moment-timezone";
import { PatientFieldEnum } from "../../constants/enums";

import { StyledText } from "../../components/LayoutComponent";

const { Title } = Typography;
const { Panel } = Collapse;

const PanelTitle = styled(Title)`
  margin-bottom: 0 !important;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: scroll;
`;

const StyledCard = styled(Card)`
  min-width: 300px;
  background: #fff;
  text-transform: capitalize;

  & .ant-card-head {
    border-bottom: 1px solid #e1e6ed;
  }

  & .ant-card-head-title {
    font-weight: 700;
    font-size: 1.5 rem;
  }

  & p {
    margin: 0;
  }
`;

const TreatmentTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CaseLink = styled.p`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #7eae80;
  }
`;

const Logo = styled.img`
  height: 20px;
`;

const CasePatientHistory = ({
  currentCaseId,
  patientId,
  setCaseId,
  setActiveKey,
}) => {
  const [patientHistory, setPatientHistory] = useState([]);
  const [prescriptionHistory, setPrescriptionHistory] = useState(null);
  const [caseHistory, setCaseHistory] = useState(null);
  let history = useHistory();

  const fetchPatientPrescriptionHistory = async () => {
    const errorMessage = "Unable to load patient prescription history"

    try {
      const { data: { error, prescriptions }} = await getPatientPrescriptionHistory(patientId);
      if (error) return message.error(errorMessage)

      return setPrescriptionHistory(
        prescriptions.sort(
          (a, b) => new Date(b.writtenDate) - new Date(a.writtenDate)
        )
      );
    } catch (e) {
      message.error(errorMessage);
    }
  };

  const fetchPatientDetails = async () => {
    try {
        const { data } = await getPatientDetails(patientId)
        const { history } = data.patient
        setPatientHistory(history)
    } catch (e) {
        console.log('error', e)
        message.error("Unable to load Patient details. Please try again!");
    }
  };

  const fetchCaseHistory = async () => {
    try {
      const response = await getCaseHistory(patientId);
      if (response.data && response.data.patientCaseHistory) {
        const { patientCaseHistory } = response.data;
        const otherCases = patientCaseHistory.filter(
          (patientCase) => patientCase._id !== currentCaseId
        );
        return setCaseHistory(
          otherCases.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
          )
        );
      }
      return message.error("Unable to load patient case history");
    } catch (e) {
      message.error("Unable to load patient case history");
    }
  };

  const caseRedirect = (caseId) => {
    setCaseId(caseId);
    setActiveKey("1");
    history.push(`/cases/${caseId}`);
  };

  useEffect(() => {
    fetchPatientPrescriptionHistory();
    patientId && fetchPatientDetails();
    patientId && fetchCaseHistory();
  }, [currentCaseId]);

  const ProfileUpdateCard = ({ eventGroup }) => {
    const updateDate = new Date(eventGroup.createdAt).toLocaleDateString()
    const updateTime = new Date(eventGroup.createdAt).toLocaleTimeString()

    return (
        <StyledCard size="small" title={`${updateDate} ${updateTime}`}>
            { Object.entries(eventGroup.updates).map(([k, v], i) => <p key={`${eventGroup.createdAt}${i}`}><StyledText>{PatientFieldEnum[k]}:</StyledText> {v}</p>) }
        </StyledCard>
    )
}

const PrescriptionCard = ({ script }) => {
  
  const { 
      name,
      strength,
      quantity,
      refills,
      directions,
      daysSupply,
      comment,
      startDate, 
      signedAt,
      medicationStatus
  } = script

  const formattedWrittenDate = moment(startDate || signedAt).tz(moment.tz.guess()).format('MM/DD/YYYY')

  return (
      <StyledCard 
          size="small" 
          title={
              <>
                  {`${formattedWrittenDate} - ${name}`}
              </>
          }>
          { strength && <p><StyledText>Strength:</StyledText> {strength}</p> }
          { quantity && <p><StyledText>Quantity:</StyledText> {quantity}</p> }
          { daysSupply && <p><StyledText>Days Supply:</StyledText> {daysSupply}</p> }
          { refills && <p><StyledText>Refills:</StyledText> {refills}</p> }
          { directions && <p><StyledText>Directions:</StyledText> {directions}</p> }
          { medicationStatus && <p><StyledText>Status:</StyledText> {medicationStatus} </p> }
          { comment && <p><StyledText>Comment:</StyledText> {comment} </p> }
      </StyledCard>
  )
}

  const TreatmentCard = ({ treatment }) => {
    const { _id, createdDate, consultationType, prescriptionStatus, followUp, teleMedicineCategory, status, logoUrl } = treatment
    const formattedCreatedDate = moment(createdDate).tz(moment.tz.guess()).format('MM/DD/YYYY')

    return (
        <StyledCard 
            size="small" 
            title={
                <TreatmentTitle>
                    {formattedCreatedDate} 
                     <Logo src={logoUrl}/>
                </TreatmentTitle>
            }
        >
            { teleMedicineCategory && <p><StyledText>Category: </StyledText>{teleMedicineCategory.name}</p> }
            { consultationType && <p><StyledText>Consult: </StyledText>{consultationType}</p> }
            { status && <p><StyledText>Case Status: </StyledText>{status}</p> }
            { followUp !== undefined && <p><StyledText>Follow Up: </StyledText>{followUp.toString()}</p> }
            { prescriptionStatus && <p><StyledText>Perscription Status: </StyledText>{prescriptionStatus}</p> }
            <hr />
            <StyledText>Link to Case:</StyledText>
            <CaseLink onClick={() => caseRedirect(_id)}>{_id}</CaseLink>
        </StyledCard>
    )
}

  return (
    <Collapse defaultActiveKey={["1", "2", "3"]}>
       <Panel header={<PanelTitle level={3}>Prescriptions</PanelTitle>} key="2">
        <ItemContainer>
          {prescriptionHistory && prescriptionHistory.length
            ? prescriptionHistory.map((script) => (
                <PrescriptionCard script={script} />
              ))
            : "No Prescription History"}
        </ItemContainer>
      </Panel>
        <Panel
        header={<PanelTitle level={3}>Other Treatments</PanelTitle>}
        key="1"
      >
        <ItemContainer>
          {caseHistory && caseHistory.length
            ? caseHistory.map((_case) => <TreatmentCard treatment={_case} />)
            : "No Past Treatments"}
        </ItemContainer>
      </Panel>
      <Panel
        header={<PanelTitle level={3}>Patient Profile Changes</PanelTitle>}
        key="3"
      >
        <ItemContainer>
          {patientHistory.length
            ? patientHistory.map((eventGroup) => (
                <ProfileUpdateCard eventGroup={eventGroup} />
              ))
            : "None"}
        </ItemContainer>
      </Panel>
    </Collapse>
  );
};

export default CasePatientHistory;
