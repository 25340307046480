import { createContext, useCallback } from 'react';
import useLocalStorage from './useLocalStorage';

export const TablePreferencesContext = createContext();

const defaultPreferences = { currentPage: 1, tablePageSize: 100, dateSortOrder: 'ascend' };

export const useTablePreferences = () => {
  const [tablePreferences, setTablePreferences] = useLocalStorage('table-preferences', {}, JSON.parse);

  const updateTablePreferences = useCallback((key, updates) => {
    setTablePreferences(t => ({
      ...t,
      [key]: t[key]
        ? { ...t[key], ...updates }
        : { ...updates }
    }))
  }, [setTablePreferences])

  const getTablePreferences = useCallback((key, defaults = defaultPreferences) => {
    if (!(key in tablePreferences)) {
      updateTablePreferences(key, defaults);
      return defaults;
    }
    else {
      return tablePreferences[key];
    }
  }, [tablePreferences, updateTablePreferences])

  return {
    getTablePreferences,
    updateTablePreferences,
  }
}
